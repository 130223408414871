<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.hub_id" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="requisition_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Requisition No.</th>
              <th>Hub Zone</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition, i) in requisitions.data" :key="i">
              <td>{{ requisitions.from + i }}</td>
              <td>{{ requisition.requisition_number }}</td>
              <td>{{ requisition.hub.hub_zone.hub_zone_desc }}</td>
              <td>
                <a href="javascript: void(0);" v-b-modal.item-requisition-status-modal @click="$bvModal.show('item-requisition-status-modal'), status(requisition.max_item_requisition_status, requisition)" class="btn btn-sm btn-primary">
                  <i class="fe fe-eye"/>
                </a>
              </td>
              <td>{{ customDate(requisition.created_at) }}</td>
              <td>
                <router-link :to="{ name: 'warehouseHubRequisitionView', params: { requisition_id: requisition.id } }" class="btn btn-sm btn-info mr-1" tag="a">
                  <i class="fa fa-eye"/> View
                </router-link>
                <router-link  v-if="user.role.includes('store_in_charge')" :to="{ name: 'warehouseHubRequisitionTransferView', params: { requisition_id: requisition.id } }" class="btn btn-sm btn-success mr-1" tag="a">
                  <i class="fa fa-paper-plane"/> Transfer
                </router-link>
                <router-link :to="{ name: 'warehouseHubRequisitionTransferDetail', params: { requisition_id: requisition.id } }" class="btn btn-sm btn-primary mr-1" tag="a">
                  <i class="fa fa-info-circle"/> Transfer Details
                </router-link>
                <a-button class="btn btn-sm btn-success mr-2" :loading="pdfDownloadLoader && i == index" @click.prevent="pdfDownload(requisition.id, index = i)"><i class="fa fa-file-pdf-o mr-1"></i> Download</a-button>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="requisitions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="requisitions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <status :current="current" :requisition="requisition"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import Status from '@/views/requisition/partial/status'
import { mapGetters } from 'vuex'

export default {
  name: 'List',
  components: { Empty, Status },
  data() {
    return {
      hubs: [],
      requisitions: {},
      requisition: {},
      loading: false,
      pdfDownloadLoader: false,
      index: -1,
      btnLoading: false,
      flag: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      current: 0,
      search_data: {
        hub_id: '',
        date_range: '',
      },
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    search() {
      this.flag = true
      this.loading = true
      this.btnLoading = true
      apiClient.post('api/hub/pending-transfer/requisition/search', this.search_data)
        .then(response => {
          this.btnLoading = false
          this.loading = false
          this.flag = true
          this.requisitions = response.data.requisitions
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/hub/pending-transfer/requisition/search?page=' + page, this.search_data)
        .then(response => {
          this.requisitions = response.data.requisitions
        })
    },
    pdfDownload(requisitionId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/requisition/pdf-download/' + requisitionId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'requisition.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    status(itemRequisitionStatus, requisition) {
      this.current = 0
      this.requisition = ''
      this.current = itemRequisitionStatus
      this.requisition = requisition
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
